import React from "react";
import "../styles/Home.css"

export default function Home(){
    return (
    <div className="Home">
        <div className="intro">
            <h1>Hi, i'm Eltina Ngwarai</h1>
            <p>Junior Front-end Web Developer</p>
        </div>
    </div> 
    );
}